import {
  buildCollection
} from "@firecms/core";
import { Chatbot } from "./chatbots";
import { chatbotCollectionProperties, chatbotCollectionPromptProperties } from "./chatbots_properties";

const { apiKey, ...relevantChatbotChangelogProperties } = chatbotCollectionProperties;

export const chatbotsChangesCollection = buildCollection<Omit<Chatbot, "apiKey">>({
  name: "Changelog",
  singularName: "Chatbot Versions",
  textSearchEnabled: false,
  path: "changelog",
  id: "changelog",
  icon: "chat",
  editable: false,
  initialSort: ["updatedAt", "desc"],
  hideIdFromForm: true,
  hideIdFromCollection: true,
  group: "Main",
  permissions: ({ authController, user }) => ({
    read: true,
    edit: false,
    create: false,
    delete: false
  }),
  properties: { ...relevantChatbotChangelogProperties, ...chatbotCollectionPromptProperties }
})
