import {
  buildProperty
} from "@firecms/core";
import { v4 as uuidv4 } from "uuid";
import { Assistant } from "./chatbots";

export const chatbotAssistantProperty = buildProperty<Assistant>({
  name: "Assistant",
  validation: { required: false },
  dataType: "map",
  expanded: false,
  properties: {
    name: buildProperty({
      name: "Name",
      validation: { required: false },
      dataType: "string"
    }),
    avatar: buildProperty({
      name: "Avatar",
      validation: { required: false },
      dataType: "string",
      storage: {
        storagePath: "images",
        acceptedFiles: ["image/*"],
        maxSize: 1024 * 1024,
        metadata: {
          cacheControl: "max-age=1000000"
        },
        fileName: (context) => {
          return uuidv4();
        }
      }
    })
  }
});
