import { TriggerConfiguration } from "./collections/triggers";

export const getDefaultSampleTriggers = (): {
  singleLocationTrigger: TriggerConfiguration,
  multiLocationTrigger: TriggerConfiguration
} => {
  return {
    singleLocationTrigger: {
      name: "Dashboard Event Lead v3",
      onEvent: "updated",
      onFieldsCompleted: ["name", "phone OR email", "appointment_date"],
      headerType: "static",
      headers: {
        Authorization: "Bearer API_KEY"
      },
      conditionalHeaders: [],
      endpoint: "https://rest.gohighlevel.com/v1/contacts/",
      body: {
        "customField.newexisting": "data.new_or_existing_patient",
        "customField.ai_chat_flow": "data.ai_chat_flow",
        "customField.appointment_request": "data.appointment_date",
        email: "data.email",
        name: "data.name",
        phone: "data.phone",
        source: "client.referral"
      }
    },
    multiLocationTrigger:
      {
        name: "Dashboard Event Lead MultiLocation v3",
        onEvent: "updated",
        onFieldsCompleted: ["name", "phone OR email", "appointment_date", "office_location"],
        headerType: "static",
        headers: {
          Authorization: "Bearer API_KEY"
        },
        conditionalHeaders: [],
        endpoint: "https://rest.gohighlevel.com/v1/contacts/",
        body: {
          "customField.newexisting": "data.new_or_existing_patient",
          "customField.ai_chat_flow": "data.ai_chat_flow",
          "customField.appointment_request": "data.appointment_date",
          "customField.office_location": "data.office_location",
          email: "data.email",
          name: "data.name",
          phone: "data.phone",
          source: "client.referral"
        }
      }
  }
}
