import { StorageSource } from "@firecms/core";
import config from "./config";

export async function uploadCode(code: string, storageSource: StorageSource, id: string) {
    const newScriptBlob = new File([code], "newScriptsonblob.js", {
        type: "text/javascript"
    });
    const uploadedFile = await storageSource.uploadFile({
        file: newScriptBlob,
        fileName: `${id}.js`,
        path: "/websites/scripts/",
        metadata: {
        }
    });
    console.log(`Uploaded file to ${uploadedFile.path}`);
    const cdnUploadedFile = await storageSource.uploadFile({
        file: newScriptBlob,
        fileName: `${id}.js`,
        path: "",
        metadata: {},
        bucket: config.CDN_BUCKET
    });
    console.log(`Uploaded file to ${cdnUploadedFile.path}, for bucket ${config.CDN_BUCKET}`);
    //const downloadLink = await storageSource.getDownloadURL(uploadedFile.path);
    return `<script type='text/javascript' async src="${config.CDN_BUCKET_URL}/${id}.js"></script>`;
}
