import {
  buildCollection,
  buildProperty,
  buildEntityCallbacks,
  EntityValues
} from "@firecms/core";
import { ContentCopyIcon } from "@firecms/ui";
import { DocumentReference } from "firebase/firestore";
import { createChatbotCode } from "../code_gen";
import { uploadCode } from "../upload_file";
import { getEnumMapOfChatbotWidgets } from "../chatbot_widget";
import { getChatbot, getChatbotWidgetVersion } from "./chatbot/chatbots";

export interface Websites {
  name: string;
  website_url?: string;
  chatbot_enabled: boolean;
  chatbot_version: string;
  salesforce_onboarding_id: string;
  chatbot_ref?: DocumentReference; // This reference contains the chatbot to be used in the website.
  // The format of the chatbot_script_storage_ref is the following: /website/scripts/{website_id}.js
  chatbot_script_storage_ref?: string; // this contains the reference to the storage where we are saving the script
  chatbot_original_script: string;
  script?: string; // This contains the script to be embbded in the website.
  created_at: Date;
}

const websiteCallbacks = buildEntityCallbacks<Websites>({
  async onPreSave(entitySaveProps): Promise<Partial<EntityValues<Websites>>> {
      const dataSource = entitySaveProps.context.dataSource;
      const storageSource = entitySaveProps.context.storageSource;
      if (entitySaveProps.entityId) {
        const websiteId = entitySaveProps.entityId;
        const chatbot = await getChatbot(dataSource, entitySaveProps.values.chatbot_ref);
        const chatbotWidget = getChatbotWidgetVersion(entitySaveProps.values.chatbot_version);
        const code = createChatbotCode(chatbotWidget, chatbot, entitySaveProps.values);
        entitySaveProps.values.script = await uploadCode(code, storageSource, websiteId);
        entitySaveProps.values.chatbot_original_script = code;
      }
      return entitySaveProps.values;
  }
});

export const websitesCollection = buildCollection<Websites>({
  id: "websites",
  name: "Websites",
  path: "websites",
  group: "Main",
  icon: "web",
  textSearchEnabled: true,
  callbacks: websiteCallbacks,
  entityActions: [
    {
      icon: <ContentCopyIcon/>,
      name: "Copy Script",
      onClick({
                entity,
                collection,
                context,
              }): Promise<void> {
        const code = entity.values.chatbot_original_script;
        if(!code) {
          context.snackbarController.open({type:"error", message:"No script to copy. You need to save the website first."});
          return Promise.resolve();
        }
        return navigator.clipboard.writeText(code).then(() => {
          context.snackbarController.open({type:"success", message:"Script copied to clipboard"});
        }).catch(err => {
          context.snackbarController.open({type:"error", message:"Failed to copy script to clipboard"});
        });
      }
    }
  ],
  permissions: ({ authController, user }) => ({
    read: true,
    edit: true,
    create: true,
    delete: true
  }),
  properties: {
    name: buildProperty({
      name: "Name",
      validation: { required: true },
      dataType: "string"
    }),
    website_url: buildProperty({
      name: "Website URL",
      description: "The URL of the website where the chatbot will be embedded.",
      url: true,
      validation: { required: false },
      dataType: "string"
    }),
    chatbot_enabled: buildProperty({
      name: "Chatbot Enabled",
      validation: { required: true },
      dataType: "boolean"
    }),
    chatbot_version: buildProperty({
      name: "Chatbot Version",
      validation: { required: true },
      dataType: "string",
      defaultValue: "production",
      enumValues: getEnumMapOfChatbotWidgets()
    }),
    chatbot_ref: buildProperty({
      name: "Chatbot",
      validation: { required: false },
      dataType: "reference",
      path: "chatbots",
      previewProperties: ["Name"]
    }),
    script: buildProperty({
      name: "Script",
      description: "The script to embed the chatbot in the website.",
      validation: { required: false },
      dataType: "string",
      readOnly: true
    }),
    chatbot_original_script: buildProperty({
      name: "Original Chatbot Script",
      description: "The original chatbot script.",
      validation: { required: false },
      dataType: "string",
      readOnly: true,
      multiline: true,
      hideFromCollection: true
    }),
    salesforce_onboarding_id: buildProperty({
      name: "Salesforce Onboarding ID",
      description: "The Salesforce Onboarding ID for the website.",
      validation: { required: false },
      dataType: "string",
      hideFromCollection: true
    }),
    created_at: buildProperty({
      name: "Created At",
      dataType: "date",
      readOnly: true,
      autoValue: "on_create",
      hideFromCollection: true
    })
  }
});
