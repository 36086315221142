import { buildCollection, buildProperty } from "@firecms/core";
import { Assistant, Customization } from "./chatbot/chatbots";
import { chatbotCustomizationWithNoDefaultsProperty } from "./chatbot/chatbot_customization_properties";
import { chatbotAssistantProperty } from "./chatbot/chatbot_assistant_properties";

interface ChatbotCustomization {
  assistant: Assistant;
  customization: Customization;
}

export interface Experiment {
  key: string;
  name: string;
  description: string;
  created_at: Date;
  starts_at: Date;
  ends_at: Date;
  enabled: boolean;
  enrolled_candidates: number;
  max_candidates: number;
  tag_filters: string[];
  weight: number;
  chatbot: ChatbotCustomization
}

export const abTestingCollection = buildCollection<Experiment>({
  name: "A/B Testing",
  singularName: "Experiment",
  textSearchEnabled: false,
  path: "experiments",
  id: "experiments",
  icon: "science",
  initialSort: ["starts_at", "desc"],
  editable: true,
  group: "Main",
  permissions: ({ authController, user }) => ({
    read: true,
    edit: authController.extra?.roles?.includes("admin"),
    create: authController.extra?.roles?.includes("admin"),
    delete: authController.extra?.roles?.includes("admin")
  }),
  properties: {
    key: buildProperty({
      name: "Key",
      validation: { required: true, unique: true },
      dataType: "string"
    }),
    name: buildProperty({
      name: "Name",
      validation: { required: true },
      dataType: "string"
    }),
    description: buildProperty({
      name: "Description",
      validation: { required: false },
      dataType: "string",
      multiline: true,
      longText: true,
      longTextRows: 2
    }),
    created_at: buildProperty({
      name: "Created At",
      dataType: "date",
      autoValue: "on_create",
      validation: { required: true }
    }),
    starts_at: buildProperty({
      name: "Starts At",
      dataType: "date",
      validation: { required: true }
    }),
    ends_at: buildProperty({
      name: "Ends At",
      dataType: "date",
      validation: { required: true }
    }),
    enabled: buildProperty({
      name: "Enabled",
      dataType: "boolean",
      validation: { required: true }
    }),
    enrolled_candidates: buildProperty({
      name: "Enrolled Candidates",
      dataType: "number",
      validation: { required: false },
      readOnly: true
    }),
    max_candidates: buildProperty({
      name: "Max Candidates",
      dataType: "number",
      validation: { required: false, min: 1, max: 5000 }
    }),
    tag_filters: buildProperty({
      name: "Tag Filters",
      dataType: "array",
      validation: { required: false },
      previewAsTag: true,
      of: {
        dataType: "string"
      }
}),
    weight: buildProperty({
      name: "Weight",
      dataType: "number",
      validation: { required: true, min: 0, max: 100 }
    }),
    chatbot: buildProperty<ChatbotCustomization>({
      name: "Chatbot",
      dataType: "map",
      validation: { required: true },
      properties: {
        assistant: chatbotAssistantProperty,
        customization: chatbotCustomizationWithNoDefaultsProperty
      }
    })
  }
})
